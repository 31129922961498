import { Document, DocumentType, Estimate, FileType, Measurement, Prisma, PurchaseOrder, Role, User } from '@prisma/client';
import { z } from 'zod';

export const dataExtensions: string[] = [FileType.QBX, FileType.XLS, FileType.XLSX];
export const imageExtensions: string[] = [FileType.JPG, FileType.JPEG, FileType.PNG, FileType.GIF, FileType.WEBP, FileType.SVG];
export const videoExtensions: string[] = [FileType.AVI, FileType.MOV, FileType.MP4, FileType.WEBM];

const fileTypeToMimeType: Omit<Record<FileType, string>, 'OTHER'> = {
	[FileType.AVI]: 'video/AV1',
	[FileType.CSV]: 'text/csv',
	[FileType.DOC]: 'application/msword',
	[FileType.DOCM]: 'application/vnd.ms-word.document.macroEnabled.12',
	[FileType.DOCX]: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	[FileType.DXF]: 'image/vnd.dxf',
	[FileType.GIF]: 'image/gif',
	[FileType.HEIC]: 'image/heic',
	[FileType.JPEG]: 'image/jpeg',
	[FileType.JPG]: 'image/jpeg',
	[FileType.MOV]: 'video/quicktime',
	[FileType.MP4]: 'video/mp4',
	[FileType.PDF]: 'application/pdf',
	[FileType.PNG]: 'image/png',
	[FileType.QBX]: 'application/vnd.intu.qbx',
	[FileType.RTF]: 'application/rtf',
	[FileType.SVG]: 'image/svg+xml',
	[FileType.TXT]: 'text/plain',
	[FileType.WEBM]: 'video/webm',
	[FileType.WEBP]: 'image/webp',
	[FileType.XLS]: 'application/vnd.ms-excel',
	[FileType.XLSX]: 'application/vnd.ms-excel',
};

const mimeTypeToFileType: Map<string, FileType> = new Map(Object.entries(fileTypeToMimeType).map(([key, value]) => [value, key as FileType]));

export function getFileTypeFromContentType(contentType: string): FileType {
	return mimeTypeToFileType.get(contentType) ?? FileType.OTHER;
}

export function getContentTypeFromFileType(fileType: FileType) {
	return fileTypeToMimeType[fileType] ?? 'text/plain';
}

export type WorkOrderDocument = Document & {
	uploadedByUser: User | null;
	estimate: Estimate | null;
	preSignedPreviewUrl?: string;
};

export type DocWithPreSignedUrl = Document & { preSignedPreviewUrl?: string };

export type DocForBilling = DocWithPreSignedUrl & { uploadedByUser: User | null };

export type AllDocsResponse = Document & {
	uploadedByUser: User | null;
	measurement: Measurement | null;
	estimate: Estimate | null;
	purchaseOrder: PurchaseOrder | null;
} & {
	preSignedPreviewUrl?: string | undefined;
};

export class DocumentSchema {
	static editSchema = z.object({
		name: z.string().optional(),
		documentType: z.nativeEnum(DocumentType).optional(),
		fileType: z.nativeEnum(FileType).optional(),
		jobId: z.number().optional(),
		isExcludedFromDailyReport: z.boolean().optional(),
	});

	static addSchema = z.object({
		name: z.string().optional(),
		location: z.string().optional(), // This needs to be optional if we're creating it and not just storing - maybe should be broken up
		bucket: z.string().optional(),
		key: z.string().optional(), // Same
		size: z.number().gt(0).optional(),
		documentType: z.nativeEnum(DocumentType).default(DocumentType.OTHER),
		fileType: z.nativeEnum(FileType),
		quoteId: z.number().optional(),
		supplierInvoiceId: z.number().optional(),
		estimateId: z.number().optional(),
		measurementId: z.number().optional(),
		checklistResponseId: z.number().optional(),
		installationId: z.number().optional(),
		checklistResponseGroupId: z.number().optional(),
		purchaseOrderId: z.number().optional(),
		quoteRequestId: z.number().optional(),
		pinId: z.number().optional(),
		floorPlanId: z.number().optional(),
		jobId: z.number().optional(),
		folderId: z.number().optional(),
		dailyReportId: z.number().optional(),
		dailyReportDocumentId: z.number().optional(),
	});

	static addManySchema = z.array(this.addSchema);

	static uploadSchema = this.addSchema.extend({ name: z.string(), jobId: z.number(), fileBase64: z.string() });

	static uploadManySchema = z.array(this.uploadSchema);

	static defaultSelect = Prisma.validator<Prisma.DocumentSelect>()({
		id: true,
		businessId: true,
		name: true,
		location: true,
		key: true,
		bucket: true,
		documentType: true,
		fileType: true,
		measurementId: true,
		estimateId: true,
		purchaseOrderId: true,
		pinId: true,
		floorplanId: true,
		jobId: true,
		createdAt: true,
		updatedAt: true,
		deletedAt: true,
		installationId: true,
	});
}

export const SaveAndSendDocumentInputSchema = z.array(
	z.object({
		documentEntityId: z.number(),
		documentType: z.nativeEnum(DocumentType),
		s3Location: z.object({
			s3Bucket: z.string(),
			s3Location: z.string(),
			s3Key: z.string(),
		}),
		fileName: z.string(),
		isExistingDocument: z.boolean().optional(),
		rawData: z
			.object({
				id: z.number(),
				businessId: z.number(),
				name: z.string().nullable(),
				location: z.string(),
				bucket: z.string(),
				key: z.string(),
				documentType: z.nativeEnum(DocumentType),
				fileType: z.nativeEnum(FileType),
				uploadedById: z.number().nullable(),
				allowedRoles: z.array(z.nativeEnum(Role)),
				size: z.number().nullable(),
				measurementId: z.number().nullable(),
				estimateId: z.number().nullable(),
				purchaseOrderId: z.number().nullable(),
				pinId: z.number().nullable(),
				floorplanId: z.number().nullable(),
				quoteId: z.number().nullable(),
				jobId: z.number().nullable(),
				folderId: z.number().nullable(),
				installationId: z.number().nullable(),
				supplierInvoiceId: z.number().nullable(),
				createdAt: z.date(),
				updatedAt: z.date().nullable(),
				deletedAt: z.date().nullable(),
				quoteRequestId: z.number().nullable(),
			})
			.optional(),
	})
);

export const SaveAndSendEmailInputSchema = z.object({
	jobId: z.number().optional(),
	quoteId: z.number().optional(),
	quoteRequestId: z.number().optional(),
	documents: z.array(
		z.object({
			documentEntityId: z.number(),
			documentType: z.nativeEnum(DocumentType),
			s3Location: z.object({
				s3Bucket: z.string(),
				s3Location: z.string(),
				s3Key: z.string(),
			}),
			fileName: z.string(),
			isExistingDocument: z.boolean().optional(),
			rawData: z
				.object({
					id: z.number(),
					businessId: z.number(),
					name: z.string().nullable(),
					location: z.string(),
					bucket: z.string(),
					key: z.string(),
					documentType: z.nativeEnum(DocumentType),
					fileType: z.nativeEnum(FileType),
					uploadedById: z.number().nullable(),
					allowedRoles: z.array(z.nativeEnum(Role)),
					size: z.number().nullable(),
					measurementId: z.number().nullable(),
					estimateId: z.number().nullable(),
					purchaseOrderId: z.number().nullable(),
					pinId: z.number().nullable(),
					floorplanId: z.number().nullable(),
					quoteId: z.number().nullable(),
					jobId: z.number().nullable(),
					folderId: z.number().nullable(),
					installationId: z.number().nullable(),
					createdAt: z.date(),
					updatedAt: z.date().nullable(),
					deletedAt: z.date().nullable(),
					supplierInvoiceId: z.number().nullable(),
					quoteRequestId: z.number().nullable(),
				})
				.optional(),
		})
	),
	to: z.string(), // Can be a comma separated list of emails.
	cc: z.string().optional(), // Can be a comma separated list of emails.
	bcc: z.string().optional(), // Can be a comma separated list of emails.
	bccSelf: z.boolean().optional(),
	subject: z.string().optional().nullable(),
	body: z.string().optional().nullable(),
	signature: z.string().optional().nullable(),
	requireCustomerSignature: z.boolean().default(false),
	includePaymentLink: z.boolean().default(false),
	syncQuickBooks: z.boolean().default(false),
	paymentAmount: z.number().min(0).nullish(),
	reminderDate: z.date().optional(),
	estimateVersion: z.union([z.string(), z.number()]).optional(),
	isWebQuote: z.boolean().optional(),
	currentUrl: z.string().optional(),
	fromOverride: z.number().optional(),
});

export const SaveAndSendSMSInputSchema = z.object({
	jobId: z.number().optional(),
	quoteId: z.number().optional(),
	quoteRequestId: z.number().optional(),
	documents: SaveAndSendDocumentInputSchema,
	recipients: z
		.array(
			z.object({
				phone: z.string(),
				name: z.string().nullable().optional(),
			})
		)
		.optional(),
	body: z.string().optional().nullable(),
	requireCustomerSignature: z.boolean().default(false),
	includePaymentLink: z.boolean().default(false),
	syncQuickBooks: z.boolean().default(false),
	paymentAmount: z.number().min(0).nullish(),
	reminderDate: z.date().optional(),
	estimateVersion: z.union([z.string(), z.number()]).optional(),
	isWebQuote: z.boolean().optional(),
	currentUrl: z.string().optional(),
});
